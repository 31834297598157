import React from 'react';

export default function RTEContentWrapper({ className, children }) {
  return (
    <div
      className={`rich-text-post-content-wrapper${
        className ? ` ${className}` : ''
      }`}
    >
      {children}
    </div>
  );
}
