/* eslint-disable react/display-name */
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import RichTextPostContentWrapper from './RichTextPostContentWrapper';
import QuoteSection from './Section/QuoteSection';
import MediaSection from './Section/MediaSection';
import LinkedImageSection from './Section/LinkedImageSection';
import DownloadFile from './Section/DownloadFile';
import { defaultOptions } from './RichText';
import BigBulletList from './BigBulletList';

const renderWithTag =
  (tagName, isVoidNode = false, includeWrapper = true) =>
  (node, children) =>
    includeWrapper ? (
      <RichTextPostContentWrapper>
        {React.createElement(tagName, null, !isVoidNode ? children : null)}
      </RichTextPostContentWrapper>
    ) : (
      React.createElement(tagName, null, !isVoidNode ? children : null)
    );

export default function RichTextPost({ content, isBlog }) {
  // Override the default options from RichText with custom renderNode rules:
  const options = {
    ...defaultOptions,
    renderNode: {
      ...defaultOptions.renderNode,
      [BLOCKS.PARAGRAPH]: renderWithTag('p'),
      [BLOCKS.HEADING_1]: renderWithTag('h1'),
      [BLOCKS.HEADING_2]: renderWithTag('h2'),
      [BLOCKS.HEADING_3]: renderWithTag('h3'),
      [BLOCKS.HEADING_4]: renderWithTag('h4'),
      [BLOCKS.HEADING_5]: renderWithTag('h5'),
      [BLOCKS.HEADING_6]: renderWithTag('h6'),
      [BLOCKS.OL_LIST]: renderWithTag('ol'),
      [BLOCKS.UL_LIST]: renderWithTag('ul'),
      [BLOCKS.LIST_ITEM]: renderWithTag('li', false, false),
      [BLOCKS.HR]: renderWithTag('hr', true),
      [BLOCKS.QUOTE]: renderWithTag('blockquote'),
      [BLOCKS.EMBEDDED_ASSET]: ({ data }) => (
        <RichTextPostContentWrapper className="embedded-asset">
          {defaultOptions.renderNode[BLOCKS.EMBEDDED_ASSET]({
            data: {
              ...data,
              target: {
                ...data.target,
                description: data.target.assetDescription,
              },
            },
          })}
        </RichTextPostContentWrapper>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => {
        switch (data.target?.__typename) {
          case 'ContentfulEmbeddedSnippet':
          case 'ContentfulCodeBlock':
            return (
              <RichTextPostContentWrapper>
                {defaultOptions.renderNode[BLOCKS.EMBEDDED_ENTRY]({
                  data,
                })}
              </RichTextPostContentWrapper>
            );

          case 'ContentfulQuoteSection':
            return !isBlog ? (
              <QuoteSection data={data.target} />
            ) : (
              <RichTextPostContentWrapper>
                <QuoteSection data={data.target} />
              </RichTextPostContentWrapper>
            );

          case 'ContentfulMediaSection':
            return <MediaSection data={data.target} />;

          case 'ContentfulLinkedImageSection':
            return <LinkedImageSection data={data.target} />;

          case 'ContentfulResourceLink':
            return !isBlog ? (
              <DownloadFile data={data.target} />
            ) : (
              <RichTextPostContentWrapper>
                <DownloadFile data={data.target} />
              </RichTextPostContentWrapper>
            );

          case 'ContentfulBulletList':
            return !isBlog ? (
              <RichTextPostContentWrapper>
                <BigBulletList
                  items={data.target.listItems}
                  bulletColor="light-blue"
                  className={`
                  portfolio-bullet-list 
                  ${data.target.useTwoColumns ? 'use-two-columns' : ''}
                  `}
                />
              </RichTextPostContentWrapper>
            ) : null;

          default:
            return null;
        }
      },
    },
  };
  return (
    <div className="rich-text-post rich-text">
      {content ? renderRichText(content, options) : null}
    </div>
  );
}
