import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import Post from '../components/BlogPost/Post';
import NewsletterForm from '../components/BlogPost/NewsletterForm';
import ReadNext from '../components/BlogPost/ReadNext';
import HeroPortfolioBlogPost from '../components/Hero/HeroPortfolioBlogPost';
import LayoutTemplate from './LayoutTemplate';

export default function BlogTemplate({ data, location }) {
  if (!data.blog) {
    return null;
  }

  const readNextCombined = data.readNext.nodes
    .concat(data.readNextAdditional.nodes)
    .slice(0, 2);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <LayoutTemplate showNav>
      <Seo
        title={data.blog.seoTitle || data.blog.blogTitle}
        description={data.blog.cardSummaryText?.cardSummaryText || ''}
        keywords={data.blog.seoKeywords}
        pathname={location.pathname}
        location={location}
        image={`https:${
          data.blog.cardImage?.file.url || data.blog.heroImage?.file.url
        }`}
        isBlogPost
      />
      <HeroPortfolioBlogPost image={data.blog.heroImage} />
      <Post data={data.blog} location={location} />
      <NewsletterForm />
      {readNextCombined.length ? <ReadNext data={readNextCombined} /> : null}
    </LayoutTemplate>
  );
}

export const query = graphql`
  query getBlog($slug: String!, $category: [String]!) {
    blog: contentfulBlogEntry(slug: { eq: $slug }) {
      id
      blogTitle
      slug
      heroImage {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
        file {
          url
        }
      }
      seoTitle
      seoKeywords
      category
      publishedDate(formatString: "MMM Do YYYY")
      cardSummaryText {
        cardSummaryText
      }
      cardImage {
        file {
          url
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          ... on ContentfulResourceLink {
            contentful_id
            __typename
            id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            downloadDescription: description
            button {
              id
              name
              buttonStyle
              text
              url
            }
          }
          ... on ContentfulEmbeddedSnippet {
            contentful_id
            __typename
            internalName
            embeddedCodeSnippet {
              embeddedCodeSnippet
            }
          }
          ... on ContentfulCodeBlock {
            contentful_id
            __typename
            title
            codeBlock {
              codeBlock
            }
          }
          ... on ContentfulQuoteSection {
            contentful_id
            __typename
            id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            backgroundColor
            quotes {
              id
              quote {
                quote
              }
              source
              link
              linkText
            }
          }
        }
      }
      authorDetails {
        name
        title
        description {
          description
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            cornerRadius: -1
            height: 70
            width: 70
            quality: 100
          )
        }
      }
    }
    readNext: allContentfulBlogEntry(
      filter: { category: { in: $category }, slug: { ne: $slug } }
      sort: { order: DESC, fields: publishedDate }
      limit: 2
    ) {
      nodes {
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        id
        category
        title: blogTitle
        publishedDate(formatString: "MMM Do YYYY")
        slug
        cardSummaryText {
          cardSummaryText
        }
        heroImage {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        cardImage {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    readNextAdditional: allContentfulBlogEntry(
      filter: { category: { nin: $category }, slug: { ne: $slug } }
      sort: { order: DESC, fields: publishedDate }
      limit: 2
    ) {
      nodes {
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        id
        category
        title: blogTitle
        publishedDate(formatString: "MMM Do YYYY")
        slug
        cardSummaryText {
          cardSummaryText
        }
        heroImage {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        cardImage {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;
