import React from 'react';
import PostCard from '../Card/PostCard';
import SectionContainer from '../SectionContainer';

export default function ReadNext({ data }) {
  return (
    <SectionContainer backgroundColor="white">
      <div className="read-next-container">
        <h2>What to read next</h2>
        <div className="read-next-card-grid">
          {data.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </div>
      </div>
    </SectionContainer>
  );
}
