import React, { useEffect, useState, useRef } from 'react';
import JSConfetti from 'js-confetti';
import ReCAPTCHA from 'react-google-recaptcha';

import ContentWrapper from '../ContentWrapper';
import LoadingIndicator from '../LoadingIndicator';
import SectionContainer from '../SectionContainer';
import { getUsersIp } from '../../utils/getUsersIp';

const Steps = {
  INIT: 'init',
  LOADING: 'loading',
  CAPTCHA: 'captcha',
  SUCCESS: 'success',
  ERROR: 'error',
};

/*
The NewsletterForm is a form that creates a new contact to GetResponse.
To prevent spam it uses Google Recaptcha V2 (The Robot Checkbox), which is shown after submitting
the form. If the checkbox fails it will automatically show the image challenge.

The actual work is done via an API call to a lambda function ("crosscomm-website-newsletter-form-submission")
which will return a 200 if the submission validates the Captcha and successfully adds the user to the
newsletter via the GetResponse API (A 200 will be returned even if GetResponse throws an error that the user
has already subscribed).
*/
export default function NewsletterForm() {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(Steps.INIT);
  const [jsConfetti, setJSConfetti] = useState(null);

  const captchaRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    switch (step) {
      case Steps.LOADING:
        if (!jsConfetti) {
          setJSConfetti(new JSConfetti());
        }
        break;

      case Steps.SUCCESS:
        if (jsConfetti) {
          jsConfetti.addConfetti();
        }
        break;

      default:
        break;
    }
  }, [step, jsConfetti]);

  const onSubmit = (event) => {
    event.preventDefault();
    setStep(Steps.CAPTCHA);
  };

  const onSubmitCaptcha = async () => {
    const formEl = formRef.current;
    const formData = new FormData(formEl);
    const captchaResponse = captchaRef.current.getValue();

    setStep(Steps.LOADING);

    const response = await fetch(process.env.GATSBY_NEWSLETTER_SUBMISSION_URL, {
      method: 'POST',
      body: JSON.stringify({
        captchaResponse,
        email: formData.get('email'),
        ipAddress: getUsersIp(),
      }),
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.status !== 200) {
      setStep(Steps.ERROR);
      return;
    }

    setStep(Steps.SUCCESS);
  };

  return (
    <SectionContainer
      backgroundColor="grey"
      className={`newsletter-container is-step-${step}`}
    >
      <ContentWrapper>
        {step === Steps.SUCCESS ? (
          <div className="newsletter-form-container">
            <h2>Thanks for signing up!</h2>
            <p>
              Check your inbox ({email}) for our welcome email (along with our
              monthly newsletters!)
            </p>
          </div>
        ) : null}
        {step === Steps.ERROR ? (
          <div className="newsletter-form-container">
            <h2>Error</h2>
            <p className="error-message">
              Something went wrong with your submission - please try again
              later.
            </p>
          </div>
        ) : null}
        {step === Steps.INIT ||
        step === Steps.LOADING ||
        step === Steps.CAPTCHA ? (
          <div className="newsletter-form-container">
            <h2>Sign up for the newsletter</h2>
            <p>
              If you want relevant updates occasionally, sign up for the private
              newsletter. Your email is never shared.
            </p>
            <form
              onSubmit={onSubmit}
              id="newsletter"
              className="form"
              method="post"
              acceptCharset="utf-8"
              ref={formRef}
            >
              <div className="form-fields-container">
                <input
                  className="text-input"
                  type="email"
                  name="email"
                  placeholder="Enter your email..."
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
                <button type="submit">Sign Up</button>
              </div>
              <div className="captcha-container">
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  onChange={onSubmitCaptcha}
                />
              </div>
            </form>
          </div>
        ) : null}

        <LoadingIndicator />
      </ContentWrapper>
    </SectionContainer>
  );
}
