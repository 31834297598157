import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import ContentWrapper from '../ContentWrapper';
import Icon from '../Icon';
import RichTextPost from '../RichTextPost';
import SectionContainer from '../SectionContainer';

export default function Post({ data, location }) {
  const [toggleFullBio, setToggleFullBio] = useState(false);

  return (
    <article className="post-container">
      <SectionContainer className="header" backgroundColor="white">
        <ContentWrapper>
          <h1>{data.blogTitle}</h1>
          <div className="post-author-date">
            <h2>Written by {data.authorDetails?.name}</h2>
            <p>{data.publishedDate}</p>
          </div>
          <hr />
          <div className="top-social-media">
            <h3>Share on:</h3>
            <ul>
              <li>
                <LinkedinShareButton url={location.href} title={data.blogTitle}>
                  <Icon
                    icon={['fab', 'linkedin-in']}
                    size="lg"
                    className="icon-color"
                  />
                </LinkedinShareButton>
              </li>
              <li>
                <TwitterShareButton url={location.href} title={data.blogTitle}>
                  <Icon
                    icon={['fab', 'twitter']}
                    size="lg"
                    className="icon-color"
                  />
                </TwitterShareButton>
              </li>
              <li>
                <FacebookShareButton url={location.href} title={data.blogTitle}>
                  <Icon
                    icon={['fab', 'facebook-f']}
                    size="lg"
                    className="icon-color"
                  />
                </FacebookShareButton>
              </li>
            </ul>
          </div>
        </ContentWrapper>
      </SectionContainer>
      <RichTextPost content={data.content} isBlog />
      <SectionContainer className="author-footer" backgroundColor="white">
        <ContentWrapper>
          <div className="post-author-share">
            <div className="social-media">
              <h3>Share on:</h3>
              <ul>
                <li>
                  <LinkedinShareButton
                    url={location.href}
                    title={data.blogTitle}
                  >
                    <Icon
                      icon={['fab', 'linkedin-in']}
                      size="lg"
                      className="icon-color"
                    />
                  </LinkedinShareButton>
                </li>
                <li>
                  <TwitterShareButton
                    url={location.href}
                    title={data.blogTitle}
                  >
                    <Icon
                      icon={['fab', 'twitter']}
                      size="lg"
                      className="icon-color"
                    />
                  </TwitterShareButton>
                </li>
                <li>
                  <FacebookShareButton
                    url={location.href}
                    title={data.blogTitle}
                  >
                    <Icon
                      icon={['fab', 'facebook-f']}
                      size="lg"
                      className="icon-color"
                    />
                  </FacebookShareButton>
                </li>
              </ul>
            </div>
            <hr />
            <div className="author-details">
              <GatsbyImage
                alt={data.authorDetails?.name}
                image={data.authorDetails?.image.gatsbyImageData}
              />
              <div className="author-name-title">
                <h2>{data.authorDetails?.name}</h2>
                {data.authorDetails?.title ? (
                  <p>{data.authorDetails?.title}</p>
                ) : null}
              </div>
            </div>
            {data.authorDetails?.description ? (
              <>
                {toggleFullBio ||
                data.authorDetails?.description.description.length < 158 ? (
                  <div className="author-bio-description-container">
                    <p className="author-bio-description">
                      {data.authorDetails?.description.description}
                    </p>
                    {toggleFullBio ? (
                      <button
                        className="author-bio-toggle-button"
                        type="button"
                        onClick={() => setToggleFullBio(!toggleFullBio)}
                      >
                        Read Less
                      </button>
                    ) : null}
                  </div>
                ) : (
                  <div className="author-bio-description-container">
                    <p className="author-bio-description">
                      {data.authorDetails?.description.description
                        .replace(/^(.{158}[^\s]*).*/, '$1')
                        .concat('...')}
                    </p>
                    <button
                      className="author-bio-toggle-button"
                      type="button"
                      onClick={() => setToggleFullBio(!toggleFullBio)}
                    >
                      Read More
                    </button>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </ContentWrapper>
      </SectionContainer>
    </article>
  );
}
