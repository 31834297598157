import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import CircleBackground from './CircleBackground';

export default function HeroPortfolioBlogPost({ image, title, subtitle }) {
  return (
    <div className="hero-container">
      <GatsbyImage
        style={{
          gridArea: '1/1',
          maxHeight: 405,
          minHeight: 334,
        }}
        alt={image?.description || image?.title}
        image={image?.gatsbyImageData}
      />
      {title ? (
        <>
          <div className="overlay-content ">
            {/* Any content here will be centered in the component */}
            <div>
              <h1 className="hero-title">{title}</h1>
              {subtitle ? <h2 className="hero-subtitle">{subtitle}</h2> : null}
            </div>
          </div>
          <div className="lower-bar-container">
            <CircleBackground />
          </div>
        </>
      ) : null}
    </div>
  );
}
